
import {defineComponent} from 'vue';

export default defineComponent({
  name: 'NavActionItem',
  props: {
    isLabel: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  setup() {
    return {};
  },
});
