
import {defineComponent, PropType} from 'vue';
import NavActionItem from '@/components/nav/NavActionItem.vue';

export default defineComponent({
  name: 'NavActionFaIcon',
  components: {
    NavActionItem,
  },
  props: {
    tooltip: {
      type: String,
    },
    icon: {
      type: [String, Array] as PropType<Icon>,
    },
  },
  setup(props, {emit}) {
    return {};
  },
});
